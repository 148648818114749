.pagination {
    margin-top: 2em;
    margin-bottom: 2em;
    grid-column: 1/-1;
    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
    }
    &__item {
        margin-left: 3px;
        margin-right: 3px;
        &.active,
        &:hover {
            .pagination__link {
                background-color: $c-accent;
                color: #fff;
            }
        }
        @include r($md) {
            margin-left: 2px;
            margin-right: 2px;
        }
    }
    &__link {
        width: 2.505em;
        height: 2.505em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border: 1px solid $c-accent;
        border-radius: 3px;
        font-size: 16px;
        font-family: $secondary-font;
        font-weight: 600;
        @include tr;
        @include r($md) {
            width: 2em;
            height: 2em;
            font-size: 12px;
        }
    }
}
