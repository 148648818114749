html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    scroll-behavior: smooth;
    height: 100%;
    line-height: $line-height;
    font-size: $rem;
    --main-header-height: 0px;
    @include r($md) {
        font-size: $rem-mobile;
    }
}
body {
    font-size: $default-font-size;
    min-height: 100%;
    min-width: 320px;
    position: relative;
    font-family: $default-font;
    overflow-x: hidden;
    color: $c-text;
    display: flex;
    flex-direction: column;
    // font smoothing
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --header-height-d1: 70px;
    --header-height-d2: 56px;
    --header-height-t1: 60px;
    --header-height-t2: 50px;
    --header-height-m1: 50px;
    --header-height-m2: 40px;
    --c-accent: #333333;
    --c-accent-active: #ea3223;
    --c-color2: #ea3223;
    --c-color3: #ffedf1;
    --c-color4: #2d6e9c;
    --c-text: #000;
    --c-gray: #dde5e5;
    --c-dark-gray: #c8d0c8;
    --c-light-gray: #e6e4e27a;
    --c-text: #1c1c1c;
    --c-blue: #2d96de;
    &:not(.show-focus-outlines) {
        a,
        button,
        .button,
        [tabindex],
        &:focus {
            outline: none;
        }
    }
    &:not(.loaded) * {
        transition: none !important;
    }
}
.no-scroll {
    overflow: hidden;
}
.section {
    padding: 60rem 0;
    position: relative;
}
::selection {
    background-color: $c-accent;
    color: #fff;
}
a:hover,
a:focus,
a:active {
    text-decoration: none;
}

img {
    max-width: 100%;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    &.img-width {
        width: 100%;
    }
}
.hidden {
    display: none !important;
}
.align-left {
    text-align: left;
}
.align-center {
    text-align: center;
}
.align-right {
    text-align: right;
}
.fill-link {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}
.sprite-icon {
    display: block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    &_outline {
        fill: none;
        stroke: currentColor;
    }
}
.hr {
    background-color: $c-border;
    height: 1px;
    border: none;
    margin: 0;
}
.bg-gray {
    background-color: $c-lightgray;
}

.bg-dark {
    background-color: $c-text;
}

.bg-white {
    background-color: #fff;
}

.bg-accent {
    background-color: $c-accent;
}

.text-white {
    color: #fff;
}

.text-dark {
    color: $c-text;
}

.text-accent {
    color: $c-accent;
}

.text-green {
    color: $c-color2;
}

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, 125, 150, 200);
$sides: (top, bottom, left, right);

// that means mt-5: 5px, mt-100: 100px, pl-5: 5px, etc. It goes for margin and padding for each of top, bottom, left, right

@each $space in $spaceamounts {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}rem;
        }
        .p#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}rem;
        }
    }
}
