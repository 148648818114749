@import "vars";

// reset headings
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-size: 100%;
    font-weight: inherit;
}
// reset paragraphs
p {
    margin: 0;
}
// reset lists
ol,
ul,
li {
    margin: 0;
    padding: 0;
} // Navigation lists do not include a marker style
nav ol,
nav ul {
    list-style: none;
}
// reset definition lists
dl,
dt,
dd {
    margin: 0;
}
figure {
    margin: 0;
}
// reset blockquotes
blockquote {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}
blockquote,
q {
    quotes: none;
}
// reset paragraphs
p {
    margin: 0;
}
// reset iframe
iframe {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

// do not repeat backgrounds
*,
::before,
::after {
    background-repeat: no-repeat;
}
// 1. Change the font styles in all browsers.
// 2. Remove the margin in Firefox and Safari.

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.2;
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

// Remove the margin in all browsers.

body {
    margin: 0;
}
main {
    display: block;
}
html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
// IE10+ doesn't honor `<meta name="viewport">` in some cases.
@at-root {
    @-ms-viewport {
        width: device-width;
    }
}

body {
    margin: 0;
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base
[tabindex="-1"]:focus {
    outline: 0 !important;
}
// Content grouping
//
// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.

hr {
    box-sizing: content-box;
    // 1
    height: 0;
    // 1
    overflow: visible;
    // 2
}

// Typography

// Abbreviations

// 1. Remove the bottom border in Firefox 39-.
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
// 3. Add explicit cursor to indicate changed behavior.
// 4. Duplicate behavior to the data-* attribute for our tooltip plugin

abbr[title],
abbr[data-original-title] {
    text-decoration: underline dotted;
    cursor: help;
    text-decoration-skip-ink: none;
}

address {
    font-style: normal;
    margin: 0;
    padding: 0;
    border: 0;
    line-height: inherit;
}

dfn {
    font-style: italic;
    // Add the correct font style in Android 4.3-
}
b,
strong {
    font-weight: bolder;
    // Add the correct font weight in Chrome, Edge, and Safari
}
// stylelint-enable font-weight-notation
small {
    font-size: 80%;
    // Add the correct font size in all browsers
}
//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
//

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}

//
// Links
//

a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
    // Remove the gray background on active links in IE 10.
    -webkit-text-decoration-skip: objects;
    // Remove gaps in links underline in iOS 8+ and Safari 8+.

    text-decoration-skip-ink: none;
    // Solid text-decoration line

    transition: $trs-dur;
    // Add transition to all links
    &:hover {
        text-decoration: none;
    }
}

// And undo these styles for placeholder links/named anchors (without href)
// which have not been made explicitly keyboard-focusable (without tabindex).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }
}

// stylelint-disable font-family-no-duplicate-names
pre,
code,
kbd,
samp {
    font-family: monospace, monospace;
    // Correct the inheritance and scaling of font size in all browsers.
    font-size: 1em;
    // Correct the odd `em` font sizing in all browsers.
}
// stylelint-enable font-family-no-duplicate-names

pre {
    // Remove browser default top margin
    margin-top: 0;
    // Reset browser default of `1em` to use `rem`s
    // Don't allow content to break outside
    overflow: auto;
    // We have @viewport set which causes scrollbars to overlap content in IE11 and Edge, so
    // we force a non-overlapping, non-auto-hiding scrollbar to counteract.
    -ms-overflow-style: scrollbar;
}

//
// Images and content
//

img,
svg {
    vertical-align: middle;
}

img {
    // Remove the border on images inside links in IE 10-.
    border: none;
}
svg:not(:root) {
    overflow: hidden;
    // Hide the overflow in IE
}

//
// Tables
//

table {
    border-collapse: collapse;
    border-spacing: 0;
    // Prevent double borders
}
caption {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    // Matches default `<td>` alignment by inheriting from the `<body>`, or the
    // closest parent with a set `text-align`.
    text-align: inherit;
    font-weight: inherit;
}

//
// Forms
//

label {
    // Allow labels to use `margin` for spacing.
    display: inline-block;
}

// Remove the default `border-radius` that macOS Chrome adds.
//
// Details at https://github.com/twbs/bootstrap/issues/24093

button {
    border-radius: 0;
}

button,
input {
    overflow: visible; // Show the overflow in Edge
}

button,
select {
    text-transform: none;
    // Remove the inheritance of text transform in Firefox
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
// 3. Opinionated: add "hand" cursor to non-disabled button elements.

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
    &:not(:disabled) {
        cursor: pointer;
    }
    // Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
}
::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    // 1. Add the correct box sizing in IE 10-
    padding: 0;
    // 2. Remove the padding in IE 10-
}

input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    // Remove the default appearance of temporal inputs to avoid a Mobile Safari
    // bug where setting a custom line-height prevents text from being vertically
    // centered within the input.
    // See https://bugs.webkit.org/show_bug.cgi?id=139848
    // and https://github.com/twbs/bootstrap/issues/11266
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    // Remove the default vertical scrollbar in IE.
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    resize: vertical;
}

fieldset {
    // Browsers set a default `min-width: min-content` on fieldsets,
    // unlike e.g. `<div>`s, which have `min-width: 0` by default.
    // So we reset that to ensure fieldsets behave more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359
    // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
    min-width: 0;
    // Reset the default outline behavior of fieldsets so they don't affect page layout.
    padding: 0;
    margin: 0;
    border: 0;
}

// 1. Correct the text wrapping in Edge and IE.
// 2. Correct the color inheritance from `fieldset` elements in IE.
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    // 1
    padding: 0;
    line-height: inherit;
    color: inherit;
    // 2
    white-space: normal;
    // 1
}

progress {
    vertical-align: baseline;
    // Add the correct vertical alignment in Chrome, Firefox, and Opera.
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    // 2. Correct the outline style in Safari.
    -webkit-appearance: none;
}

//
// Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
//

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

//
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
//

::-webkit-file-upload-button {
    font: inherit;
    // 2
    -webkit-appearance: button;
    // 1
}

//
// Correct element displays
//

output {
    display: inline-block;
}

summary {
    display: list-item;
    // Add the correct display in all browsers
    cursor: pointer;
}

template {
    display: none;
    // Add the correct display in IE
}

// Hidden attribute
//
// Always hide an element with the `hidden` HTML attribute.
[hidden] {
    display: none !important;
}
// ARIA roles include visual cursor hints
[aria-busy="true"] {
    cursor: progress;
}
[aria-controls] {
    cursor: pointer;
}
[aria-disabled="true"],
[disabled] {
    cursor: default;
}
audio,
video {
    display: block;
}

body {
    display: none;
}
