@use "sass:math";

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false) {
    @font-face {
        font-family: $font-family;
        font-weight: $weight;
        font-style: $style;
        font-display: swap;

        @if $asset-pipeline == true {
            src:
                font-url("#{$file-path}.woff2") format("woff2"),
                font-url("#{$file-path}.woff") format("woff");
        } @else {
            src:
                url("#{$file-path}.woff2") format("woff2"),
                url("#{$file-path}.woff") format("woff");
        }
    }
}

@mixin centerer($horizontal: true, $vertical: true) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    } @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

//responsive mixins
// width
@mixin r($width) {
    @media only screen and (max-width: $width) {
        @content;
    }
}
@mixin rmin($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}

// height
@mixin rh($height) {
    @media only screen and (max-height: $height) {
        @content;
    }
}
@mixin rhmin($height) {
    @media only screen and (min-height: $height) {
        @content;
    }
}

// default bg rules
@mixin bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@mixin bg-right {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
}

//run hardware acceleration.
@mixin gpu {
    transform: translate3d(0, 0, 0);
}

//css triangle
@mixin arr($width, $height, $bg, $direction) {
    width: 0px;
    height: 0px;
    border-style: solid;
    @if $direction == t {
        border-width: 0 math.div($width, 2) + px $height + px math.div($width, 2) + px;
        border-color: transparent transparent $bg transparent;
    }
    @if $direction == r {
        border-width: $height / 2 + px 0 $height / 2 + px $width + px;
        border-color: transparent transparent transparent $bg;
    }
    @if $direction == b {
        border-width: $height + px math.div($width, 2) + px 0 math.div($width, 2) + px;
        border-color: $bg transparent transparent transparent;
    }
    @if $direction == l {
        border-width: $height / 2 + px $width + px $height / 2 + px 0;
        border-color: transparent $bg transparent transparent;
    }
    @if $direction == tl {
        border-width: $height + px $width + px 0 0;
        border-color: $bg transparent transparent transparent;
    }
    @if $direction == tr {
        border-width: 0 $width + px $height + px 0;
        border-color: transparent $bg transparent transparent;
    }
    @if $direction == br {
        border-width: 0 0 $height + px $width + px;
        border-color: transparent transparent $bg transparent;
    }
    @if $direction == bl {
        border-width: $width + px 0 0 $height + px;
        border-color: transparent transparent transparent $bg;
    }
}
// example;
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

@mixin hide-scrollbars {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

@mixin custom-scrollbar {
    scrollbar-color: #0000005c #f1f1f1;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: #f1f1f1;
        border-radius: 9px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #0000005c;
        border-radius: 9px;
    }
    &:hover {
        scrollbar-color: #000000b0 #f1f1f1;
        &::-webkit-scrollbar-thumb {
            background-color: #000000b0;
        }
    }
}

@mixin line-clamp($font-size, $line-height, $lines-to-show) {
    @if unitless($line-height) == false {
        $line-height: create-unitless-line-height($font-size, $line-height);
    }

    display: block; // Fallback for non-webkit browsers
    display: -webkit-box;
    font-size: $font-size;
    height: ($font-size * $line-height * $lines-to-show); // Fallback for non-webkit browsers
    line-height: $line-height;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines-to-show;
}

@mixin tr($property: all, $type: linear) {
    transition: #{$property} $trs-dur #{$type};
}

@mixin object-fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
