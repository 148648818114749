.hidden {
    display: none;
}

.page-header {
    background-color: $c-gray;
    .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        min-height: 190rem;
        padding-top: 1%;
        padding-bottom: 3%;
        @include r($lg) {
            min-height: unset;
            padding-top: 5%;
            flex-wrap: wrap;

            .page-title,
            .header-select {
                margin-top: 10rem;
                margin-bottom: 10rem;
            }
        }
    }
}

.trigger-topics {
    display: flex;
    align-items: center;
    font-size: 18rem;
    font-family: $secondary-font;
    font-weight: 600;
    cursor: pointer;
    .sprite-icon {
        margin-left: 6rem;
    }
    @include r($lg) {
        display: none;
    }
}

.category-listing {
    background-color: #fff;
    width: 300px;
    height: calc(100% - var(--main-header-height));
    position: fixed;
    right: -320px;
    top: var(--main-header-height);
    z-index: 11;
    padding: 10rem 15rem 30rem;
    box-shadow: 0px 4px 6px 10px rgba(0, 0, 0, 0.15);
    @include tr;
    .container {
        height: 100%;
    }
    @include rmin($lg) {
        .container {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
    @include r($lg) {
        box-shadow: unset;
        width: 100%;
        right: -110%;
        height: calc(100% - var(--main-header-height) - 50px);
    }
    &__close {
        margin-bottom: 8px;
        font-size: 25px;
        width: 1em;
        height: 1em;
        margin-left: auto;
        .sprite-icon {
            margin-left: auto;
            cursor: pointer;
            &:hover {
                color: $c-red;
            }
        }
        @include rmin($lg) {
            display: none;
        }
    }
    .fields-set {
        margin-top: 0;
    }

    ul {
        height: calc(100% - 120rem);
        overflow-y: auto;
        @include custom-scrollbar;
        list-style-type: none;
        li {
            margin-bottom: 0.4em;
            a {
                background-color: $c-gray;
                display: block;
                padding: 0.8em 0.75em;
                @include tr;
                &:hover {
                    background-color: $c-color2;
                    color: #fff;
                }
                @include r($lg) {
                    font-size: 16px;
                }
                @include r($md) {
                    font-size: 14px;
                }
            }
        }
    }
}

.category-listing-active {
    overflow: hidden;
    .category-listing {
        right: 0;
    }
}

.news-listing-area {
    display: flex;
    justify-content: space-between;
}

.news-widget {
    width: 400px;
    padding-left: 70px;
    flex-shrink: 0;
    @include r($xxl) {
        padding-left: 30rem;
        width: 310px;
    }
    @include r($xl) {
        padding-left: 20rem;
        width: 275px;
    }
    @include r($lg) {
        width: 100%;
        right: -110%;
        position: fixed;
        height: calc(100% - var(--main-header-height));
        top: var(--main-header-height);
        box-shadow: 0px 4px 6px 10px rgba(0, 0, 0, 0.15);
        z-index: 4;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
        @include tr;
    }
}

.news-widget-active {
    @include r($lg) {
        overflow: hidden;
        .news-widget {
            right: 0;
        }
    }
}

.news-list {
    border-right: 1px solid $c-border;
    padding-right: 4%;
    @include r($xxl) {
        padding-right: 30rem;
    }
    @include r($xl) {
        padding-right: 20rem;
    }
    @include r($lg) {
        width: 100%;
        padding-right: 0;
        border-right: none;
    }
    .article {
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 3em;
        @include r($sm) {
            flex-wrap: wrap;
        }
        &__image {
            --size: 318px;
            width: var(--size);
            flex-shrink: 0;
            margin-right: 15rem;
            padding-top: calc(var(--size) * 0.66);
            @include r($xxl) {
                --size: 300px;
            }
            @include r($xl) {
                --size: 250px;
            }
            @include r($md) {
                --size: 200px;
            }
            @include r($sm) {
                --size: 100%;
            }
        }
        &__time {
            margin-top: 0.5em;
        }
        &__tag {
            margin-top: 0;
            margin-bottom: 0.5em;
        }
        &__content {
            @include r($sm) {
                margin-top: 1em;
            }
        }
        &__text {
            @include r($sm) {
            }
        }
    }

    .advert {
        margin-bottom: 1.5em;
    }
}

.subscribeBox {
    background-color: $c-gray;
    border-radius: 10px;
    padding: 64rem;
    background-image: url("../img/_dist/graphic-1.svg");
    background-position: bottom 0 left 0;
    position: relative;
    margin-bottom: 50rem;
    @include r($xxl) {
        padding: 40rem;
    }
    @include r($xl) {
        padding: 30rem;
    }
    @include r($md) {
        padding: 20rem;
    }
    .newsletter {
        color: $c-accent;
        width: 70%;
        max-width: 500px;
        &__button {
            background-color: $c-accent;
            &:hover {
                background-color: $c-color4;
            }
            @include r($sm) {
                &__text {
                    display: none;
                }
            }
        }
        &__title {
            font-size: 24rem;
            line-height: 1.4;
            @include r($md) {
                font-size: 20rem;
            }
        }
        @include r($md) {
            width: calc(100% - 65px);
        }
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        right: 4%;
        bottom: 0;
        z-index: 1;
        width: 88px;
        height: 180px;
        background-image: url("../img/_dist/mill2.svg");
        background-size: contain;
        @include r($md) {
            width: 50px;
            height: 110px;
            right: 1%;
        }
    }
}

.magazine-issue {
    background-color: $c-color3;
    padding: 35rem 28rem;
    width: 315px;
    border-radius: 10px;
    margin-left: auto;
    @include r($xxl) {
        width: 280px;
        padding: 30rem 20rem;
    }
    @include r($xl) {
        width: 250px;
        padding: 25rem 15rem;
    }
    @include r($lg) {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    .container {
        @include rmin($lg) {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
    .current_issue_img {
        img {
            border: 3px solid $c-accent;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            @include r($lg) {
                max-height: 250px;
            }
        }
    }
}

.issue-tags {
    margin-top: 1em;
    &__item {
        padding-left: 1.2em;
        font-size: 16px;
        font-weight: 700;
        position: relative;
        @include r($xl) {
            font-size: 14px;
        }
        a {
            display: block;
            width: 100%;
            padding-top: 0.4em;
            padding-bottom: 0.4em;
            @include tr;
            &:hover {
                color: $c-color4;
            }
            &:before {
                content: "";
                display: block;
                width: 0.5em;
                height: 0.5em;
                background-color: currentColor;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 0.9em;
            }
        }
    }
}

.issue-buttons {
    .button {
        width: 100%;
        justify-content: center;
        margin-top: 1em;
    }
    @include r($lg) {
        display: flex;
        flex-wrap: wrap;
        column-gap: 15rem;
        .button {
            width: auto;
            justify-content: flex-start;
        }
    }
}

.news-page-mobile-buttons {
    display: none;
    @include r($lg) {
        display: flex;
        justify-content: space-between;
        position: sticky;
        bottom: 0;
        margin-bottom: 10rem;
        z-index: 11;
        .button {
            width: 50%;
            height: 50px;
            border-radius: 0;
            justify-content: center;
            align-items: center;
            &:hover {
                background-color: $c-accent;
                border-color: $c-accent;
            }
            &_type_ghost {
                background-color: #fff;
                &:hover {
                    background-color: #fff;
                    color: $c-accent;
                    border-color: $c-accent;
                }
            }
            @include r($md) {
                font-size: 14px;
            }
            @include r($sm) {
                padding-left: 1em;
                padding-right: 1em;
            }
        }
    }
}

.news-article-top {
    .hero-slide {
        height: 38vw;
        min-height: 450px;
        @include r($lg) {
            height: auto;
        }
    }
    .hero-slide__left {
        position: relative;
        padding-bottom: 4em;
        @include r($lg) {
            height: unset;
            display: block;
            padding-top: 2em;
            padding-bottom: 0;
        }
    }
    .hero-slide__right {
        @include r($lg) {
            img {
                max-height: 66.5vw;
                height: 66.5vw;
            }
        }
    }
    &__social {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-left: calc((100vw - 14px - 1400px) / 2);
        padding-right: 4%;
        border-top: 1px solid #0000002a;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include r($sm) {
                flex-wrap: wrap;
            }
        }
        @include r($xxl) {
            padding-left: calc((100vw - 14px - 1140px) / 2);
            padding-top: 1em;
            padding-bottom: 1em;
        }
        @include r($xl) {
            padding-left: calc((100vw - 10px - 940px) / 2);
        }
        @include r($lg) {
            position: static;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0.75em;
            padding-top: 0.75em;
            margin-top: 1.5em;
        }
    }

    .news-article-source {
        font-size: 14px;
        padding-right: 2%;
        @include r($sm) {
            width: 100%;
        }
    }

    .news-article-share {
        display: inline-flex;
        justify-content: flex-end;
        column-gap: 0.5em;
        padding-left: 2%;
        @include r($sm) {
            width: 100%;
            justify-content: flex-start;
            padding-left: 0;
            padding-right: 2%;
            margin-top: 1em;
        }
    }
}

.social-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 24px;
    background-color: $c-text;
    width: 1.9em;
    height: 1.9em;
    @include tr;
    @include r($xxl) {
        font-size: 20px;
    }
    @include r($xl) {
        font-size: 18px;
    }
    .sprite-icon {
        color: #fff;
    }
    &:hover {
        background-color: $c-color2;
    }
}

.news-article {
    margin-top: 80rem;
    margin-bottom: 50rem;
    .container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .news-listing-area {
        @include r($lg) {
            flex-wrap: wrap;
        }
    }
    &__left {
        @include r($lg) {
            width: 100%;
        }
    }

    &__right {
        @include r($lg) {
            position: static;
            width: 100%;
            box-shadow: none;
            height: unset;
            margin-top: 3em;
        }
    }
}

.plaintext {
    .blockquote,
    blockquote {
        background-color: $c-gray;
        font-size: 24rem;
        font-weight: 600;
        font-family: $secondary-font;
        padding: 1.4em 1.4em 1.4em 5em;
        position: relative;
        border-radius: 0.4em;
        @include r($sm) {
            padding: 1.4em 1.25em;
        }
        &:before {
            content: "";
            display: block;
            width: 2.65em;
            height: 2.65em;
            background-color: $c-color4;
            background-image: url("data:image/svg+xml;utf8,<svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M19.9375 12.375V27.5C19.9352 29.6873 19.0653 31.7844 17.5186 33.3311C15.9719 34.8778 13.8748 35.7477 11.6875 35.75C11.3228 35.75 10.9731 35.6051 10.7152 35.3473C10.4574 35.0894 10.3125 34.7397 10.3125 34.375C10.3125 34.0103 10.4574 33.6606 10.7152 33.4027C10.9731 33.1449 11.3228 33 11.6875 33C13.1462 33 14.5451 32.4205 15.5766 31.3891C16.608 30.3576 17.1875 28.9587 17.1875 27.5V26.125H6.875C6.14565 26.125 5.44618 25.8353 4.93046 25.3195C4.41473 24.8038 4.125 24.1043 4.125 23.375V12.375C4.125 11.6457 4.41473 10.9462 4.93046 10.4305C5.44618 9.91473 6.14565 9.625 6.875 9.625H17.1875C17.9168 9.625 18.6163 9.91473 19.132 10.4305C19.6478 10.9462 19.9375 11.6457 19.9375 12.375ZM37.125 9.625H26.8125C26.0832 9.625 25.3837 9.91473 24.868 10.4305C24.3522 10.9462 24.0625 11.6457 24.0625 12.375V23.375C24.0625 24.1043 24.3522 24.8038 24.868 25.3195C25.3837 25.8353 26.0832 26.125 26.8125 26.125H37.125V27.5C37.125 28.9587 36.5455 30.3576 35.5141 31.3891C34.4826 32.4205 33.0837 33 31.625 33C31.2603 33 30.9106 33.1449 30.6527 33.4027C30.3949 33.6606 30.25 34.0103 30.25 34.375C30.25 34.7397 30.3949 35.0894 30.6527 35.3473C30.9106 35.6051 31.2603 35.75 31.625 35.75C33.8123 35.7477 35.9094 34.8778 37.4561 33.3311C39.0028 31.7844 39.8727 29.6873 39.875 27.5V12.375C39.875 11.6457 39.5853 10.9462 39.0695 10.4305C38.5538 9.91473 37.8543 9.625 37.125 9.625Z' fill='%23fff'/></svg>");
            background-size: 64%;
            background-position: center;
            border-radius: 50%;
            position: absolute;
            top: 1.4em;
            left: 1.4em;
            @include r($sm) {
                display: block;
                position: static;
                margin-bottom: 0.75em;
            }
        }
        cite {
            font-size: 16rem;
            margin-top: 0.5em;
            font-weight: 600;
            font-style: normal;
        }
    }

    .advert {
        margin-left: 0;
    }

    figure {
        img {
            border-radius: 0.25em;
        }
        figcaption {
            font-size: 14px;
            font-weight: 700;
            padding: 1.25em 0.5em;
            border-bottom: 1px solid $c-border;
            text-align: center;
        }
    }
}

.issue-includes {
    max-width: 315px;

    @include r($xxl) {
        max-width: 280px;
    }
    @include r($xl) {
        max-width: 250px;
    }
    &__title {
        font-size: 24rem;
        font-weight: 600;
        font-family: $secondary-font;
        margin-top: 1.5em;
    }
}

.article-categories {
    margin-top: 40rem;
    padding-top: 40rem;
    margin-bottom: 20rem;
    border-top: 1px solid $c-border;
    .tag {
        padding-right: 20rem;
        &:hover {
            color: $c-accent;
        }
        &:nth-child(1) {
            @include r($sm) {
                padding-left: 0;
            }
        }
    }
    strong {
        padding-right: 20rem;
        @include r($sm) {
            display: block;
            margin-bottom: 1em;
        }
    }
}

.popular-articles {
    margin-top: 2em;
    &__list {
        margin-top: 2em;
    }
    &__item {
        .tag {
            display: block;
            width: 100%;
            margin-top: 1.5em;
            margin-bottom: 1em;
            border-top: 1px solid $c-darkgray;
            padding-top: 1.5em;
        }
    }

    &__title {
        &:hover {
            color: $c-color4;
        }
    }
}

.share-buttons {
    border-top: 1px solid $c-darkgray;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: 1em;
    display: flex;
    column-gap: 2.5%;
}

.read-also {
    background-color: $c-gray;
    padding-top: 70rem;
    padding-bottom: 100rem;
}

.articles-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2.5%;
    @include r($lg) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include r($sm) {
        display: block;
    }
}

.current-issue {
    margin-bottom: 50rem;
    .news-widget {
        padding-left: 0;
    }

    .magazine-issue {
        margin-left: 0;
        background-color: $c-color3;
        margin-top: -30rem;
    }

    .news-list {
        border-right: none;
        padding-right: 0;
        margin-top: 80rem;
        padding-left: 80rem;
        border-left: 1px solid $c-border;
        width: calc(100% - 400px);
        @include r($xxl) {
            width: calc(100% - 315px);
            padding-left: 30rem;
        }
        @include r($xl) {
            width: calc(100% - 280px);
        }
        @include r($lg) {
            width: 100%;
            border-left: none;
            padding-left: 0;
            margin-top: 50rem;
        }
        .article__tag {
            margin-bottom: 0.75em;
        }
    }

    .issue-includes {
        position: sticky;
        top: var(--main-header-height);
        &__title {
            padding-bottom: 0.5em;
            border-bottom: 1px solid $c-border;
        }
    }

    @include r($lg) {
        .news-listing-area {
            flex-wrap: wrap;
        }
        .news-widget {
            position: relative;
            height: unset;
            right: unset;
            box-shadow: none;
            z-index: 101;
        }
        .magazine-issue {
            position: fixed;
            margin-top: 0;
            width: 100%;
            height: calc(100% - var(--main-header-height) - 50px);
            top: var(--main-header-height);
            z-index: 3;
            right: -105%;
            // right: 0;
            @include tr;
        }

        .issue-includes {
            position: fixed;
            background-color: #fff;
            margin-top: 0;
            width: 100%;
            max-width: 100%;
            height: calc(100% - var(--main-header-height) - 50px);
            padding-left: 15px;
            padding-right: 15px;
            top: var(--main-header-height);
            z-index: 2;
            left: -100%;
            @include tr;
            .issue-tags {
                a {
                    font-size: 15px;
                    padding-bottom: 0.5em;
                }
            }
        }
    }
}

.current-issue .issue-includes {
    margin-top: 40rem;
    padding-top: 2rem;
    @include r($lg) {
        margin-top: 0;
    }
}

.current-issue-header {
    display: flex;
    align-items: center;
    width: 100%;
    .current_issue_img {
        display: block;
        margin-bottom: -100rem;
        @include r($lg) {
            margin-bottom: -50rem;
        }
        @include r($sm) {
            margin-bottom: 0;
        }
        img {
            max-width: 250rem;
            border: 1px solid $c-accent;
            border-radius: 5px;
            @include r($lg) {
                max-width: 150rem;
            }
        }
    }
    &__c {
        padding-left: 5%;
        .button {
            padding-left: 1.5em;
            padding-right: 1.5em;
            margin-bottom: 0;
        }
    }
}

.news-page-mobile-buttons {
    &--1 {
        .button {
            width: 100%;
        }
    }
}

.current-issue-active {
    overflow: hidden;
    .current-issue {
        .magazine-issue {
            right: 0;
        }
    }
}

.inside-issue-active {
    overflow: hidden;
    .current-issue {
        .issue-includes {
            left: 0;
        }
    }
}

.listSlider {
    position: relative;
    .slide-item {
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        height: 370rem;
        @include r($sm) {
            flex-wrap: wrap;
            height: auto;
        }
        &__left {
            width: 42%;
            padding-left: 3%;
            padding-right: 3%;
            height: 100%;
            background-color: $c-gray;
            display: flex;
            align-items: center;
            height: 100%;
            .tag {
                margin-bottom: 1em;
            }
            @include r($sm) {
                width: 100%;
                padding-top: 30rem;
                padding-bottom: 30rem;
                min-height: 125px;
            }
        }
        &__right {
            width: 58%;
            height: 100%;
            background-color: $c-gray;
            img {
                @include object-fit-cover;
            }
            @include r($sm) {
                width: 100%;
                padding-top: 70%;
                position: relative;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
    .list-slider-controls {
        background-color: #fff;
        border-radius: 6px 6px 0 0;
        position: absolute;
        bottom: 0;
        right: 4%;
        z-index: 11;
        font-size: 14px;
        display: flex;
        align-items: center;
        .list-slide-pagination {
            margin-left: 1.25em;
            margin-right: 1.25em;
        }
        .list-slider-prev {
            margin-left: 20px;
            margin-top: 9px;
            margin-bottom: 9px;
        }
        .list-slider-next {
            margin-right: 20px;
            margin-top: 9px;
            margin-bottom: 9px;
        }
    }
}

.more-issues {
    background-color: $c-gray;
    padding-top: 2.5em;
    padding-bottom: 2em;
}

.newsSectionTitle {
    margin-bottom: 1em;
}

.subscription {
    display: flex;
    justify-content: space-between;
    margin-top: 50rem;
    margin-bottom: 50rem;
    font-family: $secondary-font;
    .radio {
        font-size: 15px;
    }
    &__left {
        width: 45%;
        img {
            border-radius: 2%;
        }
        @include r($xl) {
            width: 42%;
        }
        @include r($lg) {
            display: none;
        }
    }
    &__right {
        width: 50%;
        padding-top: 15rem;
        @include r($xl) {
            width: 55%;
        }
        @include r($lg) {
            width: 100%;
        }
    }

    &__type {
        background-color: $c-color3;
        border-radius: 10px;
        width: 100%;
        margin-top: 20rem;
        display: block;
        padding-top: 15rem;
        padding-bottom: 25rem;

        .radio__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 80px;
            padding-right: 20px;
            @include r($sm) {
                font-size: 10px;
            }
            &:before {
                top: 21px;
                left: 30px;
                @include r($xl) {
                    left: 13px;
                }
                @include r($sm) {
                    top: 8px;
                    left: 9px;
                }
            }
            &:after {
                top: 27px;
                left: 36px;
                @include r($xl) {
                    left: 19px;
                }
                @include r($sm) {
                    top: 12px;
                    left: 13px;
                }
            }
            @include r($xl) {
                padding-left: 50px;
            }
            @include r($sm) {
                padding-left: 35px;
                padding-right: 15px;
            }
        }
    }

    &__name {
        font-size: 24rem;
        font-weight: 600;
        display: block;
        @include r($xxl) {
            font-size: 22rem;
        }
        @include r($xl) {
            font-size: 20rem;
        }
    }

    &__cat {
        display: block;
        font-weight: 400;
    }

    &__price {
        font-size: 56rem;
        font-weight: 600;
        @include r($xxl) {
            font-size: 48rem;
        }
        @include r($xl) {
            font-size: 42rem;
        }
        @include r($sm) {
            font-size: 36rem;
        }
    }

    &__time {
        font-size: 24rem;
        font-weight: 600;
        @include r($xl) {
            font-size: 22rem;
        }
        @include r($xl) {
            font-size: 20rem;
        }
    }

    &__included {
        margin-top: 2em;
        .h5 {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }

    &__accordion {
        margin-top: 40rem;
    }

    &__form {
        margin-top: 3em;
        display: none;
    }

    &.subscriptionFormActive {
        .subscription__form {
            display: block;
        }
    }
}

.accordion {
    cursor: pointer;
    margin-top: 0.75em;
    padding: 1.25em 0 0.55em;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
    font-size: 18px;
    font-weight: 600;
    border-top: 1px solid $c-accent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40rem;
    position: relative;
    &-item {
        .plaintext {
            p ~ p {
                margin-top: 0.5em;
            }
        }
    }

    &-area {
        border-bottom: 1px solid $c-accent;
        padding-bottom: 15rem;
    }

    &:after {
        content: "\002B";
        width: 28rem;
        height: 28rem;
        padding-bottom: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 24rem;
        color: #fff;
        background-color: $c-accent;
        font-weight: bold;
        position: absolute;
        right: 0;
        margin-left: 5px;
        @include tr;
    }

    &.active + .panel {
        margin-top: 10rem;
        margin-bottom: 10rem;
    }

    &.active {
        &:after {
            content: "\2212";
            background-color: $c-color4;
        }
    }
}

.panel {
    padding: 0;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 0.25s ease-out;
}

.included {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    @include r($xl) {
        flex-wrap: wrap;
    }
    &__item {
        width: 31%;
        display: flex;
        align-items: center;
        @include r($xl) {
            width: 32%;
        }
        @include r($sm) {
            width: 100%;
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }
    &__icon {
        width: 44px;
        height: 44px;
        flex-shrink: 0;
        margin-right: 12px;
        @include r($xxl) {
            width: 40px;
            height: 40px;
        }
        @include r($xl) {
            width: 36px;
            height: 36px;
        }
    }

    &__text {
        @include r($xxl) {
            font-size: 16px;
            line-height: 1.35;
        }
        @include r($xl) {
            font-size: 15px;
        }
    }
}

.where-to-find {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}

.secure-stripe {
    &__label {
        font-size: 14px;
    }
    img {
        height: 30px;
    }
    @include r($md) {
        margin-top: 1em;
    }
}

.section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    &__image {
        width: 45%;
        position: sticky;
        top: calc(10px + var(--main-header-height));
        img {
            border-radius: 2%;
        }
        @include r($xl) {
            width: 42%;
        }
        @include r($lg) {
            display: none;
        }
    }
    &__content {
        width: 50%;
        padding-top: 15rem;
        @include r($xl) {
            width: 55%;
        }
        @include r($lg) {
            width: 100%;
        }
    }
}

.cta-element {
    background-color: $c-color3;
    border-radius: 10px;
    padding: 15rem 30rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include r($xxl) {
        padding: 15rem 25rem;
    }
    @include r($xl) {
        padding: 10rem 20rem;
    }
    @include r($sm) {
        flex-wrap: wrap;
        padding-top: 25rem;
    }
    &__content {
        display: flex;
        align-items: center;
        .plaintext {
            font-size: 16px;
            @include r($xl) {
                font-size: 14px;
            }
            @include r($sm) {
                font-size: 12px;
            }
        }
    }
    &__icon {
        width: 44rem;
        height: 44rem;
        flex-shrink: 0;
        margin-right: 10rem;
        @include r($xxl) {
            width: 38rem;
            height: 38rem;
        }
        @include r($xl) {
            width: 34rem;
            height: 34rem;
        }
        @include r($sm) {
            width: 36px;
            height: 36px;
        }
    }
    .button {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.contact {
    display: flex;
    justify-content: space-between;
    @include r($lg) {
        flex-wrap: wrap;
    }
    &__info {
        width: 45%;
        img {
            border-radius: 2%;
        }
        @include r($xl) {
            width: 42%;
        }
        @include r($lg) {
            width: 100%;
        }
    }
    &__form {
        width: 50%;
        padding-top: 15rem;
        @include r($xl) {
            width: 55%;
        }
        @include r($lg) {
            width: 100%;
        }
    }
}

.info-row {
    display: flex;
    align-items: center;
    font-family: $secondary-font;
    line-height: 1.2;
    margin-top: 1.75em;
    &__icon {
        img {
            width: 44rem;
            height: 44rem;
        }
        margin-right: 10rem;
    }
    &__text__2 {
        font-weight: 600;
    }
    a {
        &:hover {
            color: $c-color4;
        }
    }
}

.enews {
    &__included {
        padding-left: 15%;
        margin-top: 30rem;
        .info-row {
            margin-top: 0.8em;
        }
    }
}

.checkboxes-area {
    background-color: $c-gray;
    padding: 16rem 20rem;
    font-size: 16rem;
    border-radius: 4px;
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__list {
        margin-top: 1em;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 3%;
        @include r($xxl) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include r($lg) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include r($md) {
            grid-template-columns: repeat(2, 1fr);
        }
        .checkbox {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }
}

.enews {
    @include r($lg) {
        .contact__info {
            order: 2;
            .enews__included {
                padding-left: 0;
            }
        }
    }
}

.objective {
    background-color: $c-gray;
    font-family: $secondary-font;
    font-size: 24rem;
    padding: 1.5em 2em;
    border-radius: 3%;
    margin-top: 1em;
    margin-bottom: 1.5em;
    font-weight: 600;
    @include r($md) {
        padding: 1em 15px;
        font-size: 20rem;
    }
}

.boxes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
}

.box {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2%;
    height: 240px;
    @include r($xl) {
        width: 50%;
    }
    @include r($md) {
        height: 200px;
    }
    @include r($sm) {
        width: 100%;
    }
    &__content {
        text-align: center;
    }
    &__icon {
        margin-bottom: 0.5em;
    }
    &__title {
        font-size: 24rem;
        font-family: $secondary-font;
        font-weight: 600;
        @include r($xxl) {
            font-size: 22rem;
        }
    }
    .button {
        margin-top: 0.75em;
        &:hover {
            background-color: $c-color4;
            border-color: $c-color4;
            color: #fff;
        }
    }
    &__text {
        font-size: 16rem;
    }
    &--1 {
        background-color: $c-color3;
    }
    &--2 {
        background-color: $c-accent;
        color: #fff;
        .button {
            background-color: #fff;
            border-color: #fff;
            color: $c-accent;
        }
    }
    &:nth-child(3) {
        @include r($xl) {
            border-top: 1px solid $c-color2;
        }
        @include r($sm) {
            border-top: none;
        }
    }
    &:nth-child(4) {
        @include r($xl) {
            border-top: 1px solid $c-color3;
        }
        @include r($sm) {
            border-top: none;
        }
    }
}

.team {
    background-color: $c-gray;
    padding-top: 2em;
    margin-top: 3em;
    padding-bottom: 2em;
    .section-title__row {
        border-bottom: 1px solid $c-border;
    }
}

.member {
    &__area {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    width: 48%;
    display: flex;
    align-items: center;
    margin-top: 0.5em;
    margin-bottom: 1em;

    @include r($md) {
        width: 100%;
    }

    &__image {
        width: 136rem;
        height: 136rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 15rem;
        img {
            @include object-fit-cover;
        }
    }

    &__name {
        margin-top: 0.5em;
    }
}

.partners {
    --image-size: 150px;
    padding: 20px;
    overflow: hidden;
    max-width: 2000px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    .section-title__row {
        margin-bottom: 0;
    }
}
.partners:hover {
    &.moving {
        .partners-slide {
            animation-play-state: paused;
        }
    }
}
.partners {
    &.moving {
        .partners-slide {
            display: flex;
            align-items: center;
            position: relative;
            animation: marquee 25s linear infinite;
        }
    }
}
.partners img {
    display: block;
    max-height: var(--image-size);
    max-width: calc(var(--image-size) * 1.5);
    margin: 0 2vw;
    // filter: grayscale(100%);
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.header-select {
    background-color: transparent;
    border: none;
    padding-right: 45rem;
    appearance: none;
    color: currentColor;
    background-image: url(../img/_dist/graphic/arrow-down-c.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) 50%;
    outline: none;
    cursor: pointer;
}

.issue-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2%;
    @include r($lg) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include r($sm) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include r(400px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.issue {
    margin-bottom: 2em;

    &__img {
        display: block;
        width: 100%;
        padding-top: 138%;
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 0;
            @include object-fit-cover;
        }
    }

    &__title {
        margin-top: 0.75em;
        @include tr;
    }

    &__btn {
        margin-top: 0.25em;
        font-size: 16rem;
        display: flex;
        align-items: center;
        .sprite-icon {
            margin-left: 0.4em;
        }
    }
    &:hover {
        img {
            opacity: 0.9;
        }
        .issue__title {
            color: $c-color2;
        }
    }
}

.issue-pagination {
    margin-top: 2em;
    background-color: $c-gray;
    padding-top: 2em;
    padding-bottom: 2em;
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.issue-btn-pag {
    display: flex;
    align-items: center;
    gap: 1em;
    @include tr;
    &:hover {
        color: $c-color2;
        .issue-btn-icon {
            background-color: $c-color2;
        }
    }
}

.issue-btn-icon {
    font-size: 30rem;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $c-accent;
    border-radius: 50%;
    @include tr;
    svg {
        width: 0.5em;
        height: 0.5em;
        color: #fff;
    }
}

.slide-item {
    display: flex;
}

.login-header {
    .container {
        justify-content: center;
        align-items: center;
        padding-top: 2%;
        padding-bottom: 2%;
    }
}

.login-page {
    text-align: center;
    .h3 {
        margin-top: 1.5em;
    }
}

.login-form {
    margin-top: 30rem;
    max-width: 600rem;
    margin-left: auto;
    margin-right: auto;
    .form__element {
        text-align: center;
        font-size: 18rem;
    }

    .button {
        width: 100%;
        margin-top: 15rem;
        text-align: center;
        justify-content: center;
    }
}

.forgot-id-button {
    cursor: pointer;
    color: $c-color4;
    text-decoration: underline;
}

.embed-magazine {
    width: 100%;
    padding: 30px 15px;
    iframe {
        width: 100%;
        min-height: calc(100vh - var(--main-header-height) - 15px);
        @include r($sm) {
            min-height: unset;
        }
    }
}
