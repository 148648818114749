@mixin h1-styles {
    font-family: $secondary-font;
    font-size: 56rem;
    font-weight: 700;
    line-height: 1.2;
    @include r($xxl) {
        font-size: 50rem;
    }
    @include r($xl) {
        font-size: 45rem;
    }
    @include r($md) {
        font-size: 40rem;
    }
}
.h1 {
    @include h1-styles;
}
@mixin h2-styles {
    font-family: $secondary-font;
    font-size: 42rem;
    font-weight: 700;
    line-height: 1.2;
    @include r($xxl) {
        font-size: 38rem;
    }
    @include r($xl) {
        font-size: 34rem;
    }
    @include r($md) {
        font-size: 32rem;
    }
}
.h2 {
    @include h2-styles;
}
@mixin h3-styles {
    font-family: $secondary-font;
    font-size: 32rem;
    font-weight: 700;
    line-height: 1.2;
}
.h3 {
    @include h3-styles;
}
@mixin h4-styles {
    font-family: $secondary-font;
    font-size: 24rem;
    font-weight: 700;
    line-height: 1.2;
    @include r($xl) {
        font-size: 22rem;
    }
}
.h4 {
    @include h4-styles;
}
@mixin h5-styles {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    @include r($lg) {
        font-size: 18px;
    }
}
.h5 {
    @include h5-styles;
}
@mixin h6-styles {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    @include r($lg) {
        font-size: 16px;
    }
}
.h6 {
    @include h6-styles;
}
.uppercase {
    text-transform: uppercase;
}
strong,
b {
    font-weight: 600;
}
.color-accent {
    color: $c-accent;
}
.plaintext {
    font-size: 18rem;
    line-height: 1.5;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 1em;
    }
    h1 {
        @include h1-styles;
    }
    h2 {
        @include h2-styles;
    }
    h3 {
        @include h3-styles;
    }
    h4 {
        @include h4-styles;
    }
    h5 {
        @include h5-styles;
    }
    h6 {
        @include h6-styles;
    }
    p {
        + p {
            margin-top: 1.6em;
        }
    }
    a {
        color: $c-accent;
        text-decoration: underline;
        &:hover {
            color: $c-accent-hover;
        }
    }
    ol {
        padding-left: 1.2em;
    }
    ul {
        list-style: none;
        &:not([class]) {
            padding-left: 1.2em;
            list-style-type: disc;
        }
    }
    > * {
        + * {
            margin-top: 1.5em;
        }
    }
    > * + h1,
    > * + h2,
    > * + h3,
    > * + h4,
    > * + h5,
    > * + h6 {
        margin-top: 1.6em;
    }
    h1 + *,
    h2 + *,
    h3 + *,
    h4 + *,
    h5 + *,
    h6 + * {
        margin-top: 0.8em;
    }
    > * + ul,
    > * + ol {
        margin-top: 1.7em;
    }
    > ul + *,
    > ol + * {
        margin-top: 1.5em;
    }
    li {
        + li {
            margin-top: 0.25em;
        }
    }
}

// .custom-marker-list {
//     li {
//         padding-left: 2.5em;
//         background-image: url(../img/svg/graphic/list-marker.svg);
//         background-size: 1.6em 1.455em;
//         background-repeat: no-repeat;
//         background-position: left top 3px;
//     }
// }
