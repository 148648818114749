$mobile-breakpoint: $lg;

.main-menu {
    font-size: $default-font-size;
    font-family: $secondary-font;
    @include r($mobile-breakpoint) {
        text-align: left;
        padding: 0;
        z-index: -1;
        cursor: pointer;
        top: 0;
        top: calc(var(--main-header-height) - 2px);
        bottom: 0;
        left: 0;
        right: 0;
        user-select: none;
        min-height: auto;
        box-shadow: none;
        padding: 0;
        transform: scale3d(1, 0, 0);
        transform-origin: 0 0;
        position: fixed;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        @include custom-scrollbar;
        &.active {
            transition: 0.3s all ease;
            transform: none;
            padding-top: 3rem;
            background: #fff;
            z-index: 3;
        }
    }
    &__inner {
        display: flex;
        align-items: center;
        width: 100%;
        @include rmin($mobile-breakpoint) {
            position: relative;
            max-width: none;
            padding: 0;
        }
        @include r($mobile-breakpoint) {
            flex-direction: column;
            min-height: 100%;
        }
    }
    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include r($mobile-breakpoint) {
            display: block;
            width: 100%;
            user-select: none;
            z-index: 100;
            list-style-type: none;
            margin-left: 0;
            padding: 50rem 0 20rem;
            visibility: hidden;
            transform: scale3d(1, 0, 1);
            // vertical top start
            transform-origin: 50% 0;
            transition: 0.4s ease;
            &.active {
                visibility: visible;
                transform: scale3d(1, 1, 1);
            }
        }
    }
    &__item {
        position: relative;
        margin-right: 0;
        border-right: 1px solid #e5e5e5;
        // width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        &:hover {
            .main-menu__link {
                &:before {
                    opacity: 1;
                }
            }
        }
        &.active {
            .main-menu__link {
                &:before {
                    opacity: 1;
                }
            }
        }
        @include r($xl) {
            // width: auto;
            border-right: none;
        }

        @include r($mobile-breakpoint) {
            margin: 0;
            padding: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        @include r($mobile-breakpoint) {
            &.active {
                .main-menu__link {
                    color: $c-accent;
                }
            }
        }
    }
    &__link {
        width: 100%;
        display: flex;
        font-weight: 500;
        align-items: center;
        // justify-content: center;
        position: relative;
        padding-top: 0.46em;
        padding-bottom: 0.46em;
        padding-left: 1.5em;
        padding-right: 1.5em;
        color: $c-accent;
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: -11rem;
            width: 100%;
            height: 6px;
            background-color: $c-color2;
            opacity: 0;
            @include tr;
            @include r($xxl) {
                bottom: -14rem;
            }
            @include r($lg) {
                display: none;
            }
        }
        &:hover {
            &:before {
                opacity: 1;
            }
        }
        &.active {
            &:before {
                opacity: 1;
            }
        }
        @include r($xxl) {
            font-size: 16px;
            padding-left: 0.75em;
            padding-right: 0.75em;
        }
        @include r($mobile-breakpoint) {
            text-align: center;
            height: auto;
            width: 100%;
            display: flex;
            padding: 1.15em 0;
            border-bottom: 1px solid $c-border;
            &:hover {
                color: $c-color2;
            }
        }
        @include r($sm) {
            padding-top: 0.85em;
            padding-bottom: 0.85em;
        }
    }

    &__dd {
        &-container {
            @include rmin($lg) {
                &:hover {
                    .main-menu__dd-list {
                        display: block;
                    }
                }
            }
            .main-menu__link {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                @include r($xxl) {
                    justify-content: unset;
                }
                &:after {
                    content: "";
                    display: inline-block;
                    border: solid currentColor;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    @include tr;
                    @include r($xxl) {
                        position: relative;
                        left: 15px;
                        top: -1px;
                    }
                }
                &.active-dropdown {
                    &:after {
                        transform: rotate(-135deg);
                        -webkit-transform: rotate(-135deg);
                    }
                    & ~ .main-menu__dd-list {
                        @include r($lg) {
                            padding-top: 1em;
                            padding-bottom: 1em;
                            max-height: 400px;
                        }
                    }
                }
            }
        }
        &-list {
            display: none;
            position: absolute;
            background-color: #fff;
            width: 100%;
            padding-left: 1.5em;
            padding-top: 1em;
            padding-bottom: 1em;
            border-radius: 0 0 4px 4px;
            box-shadow: 0 7px 7px rgba(0, 0, 0, 0.1);
            @include r($xxl) {
                padding-left: 0.75em;
                padding-top: 0.6em;
                padding-bottom: 0.6em;
            }
            @include r($lg) {
                position: static;
                display: block;
                max-height: 0;
                overflow: hidden;
                padding-top: 0;
                padding-bottom: 0;
                box-shadow: none;
                @include tr;
            }
        }

        &-link {
            padding-bottom: 8px;
            padding-top: 6px;
            display: block;
            &:hover {
                color: $c-color2;
            }
            @include r($lg) {
                padding-top: 1em;
                padding-bottom: 1em;
                border-bottom: 1px solid $c-border;
            }
            @include r($sm) {
                font-size: 14px;
            }
        }
    }
}

.menu-opener {
    position: relative;
    display: block;
    transition: 0.3s;
    --color: #{$c-text};
    cursor: default;
    &.active {
        .menu-opener {
            &__lines {
                background-color: transparent;
                &::before {
                    transform: rotate(45deg);
                }
                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
    &__inner {
        display: block;
        position: relative;
        cursor: pointer;
        z-index: 1002;
        width: 100%;
        height: 100%;
        padding: 23rem 0 23rem 15rem;
        transition: 0.3s;
    }
    &__lines {
        display: block;
        position: relative;
        width: 23rem;
        height: 2px;
        background-color: var(--color);
        border-radius: 9px;
        transition: 0.3s;
        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: var(--color);
            border-radius: 9px;
            transition: 0.3s;
        }
        &::before {
            transform: translateY(-8px);
            @include r($md) {
                transform: translateY(-6px);
            }
        }
        &::after {
            transform: translateY(8px);
            @include r($md) {
                transform: translateY(6px);
            }
        }
    }
}
