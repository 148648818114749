// html {
//     --main-header-height: 174px;

//     @include r($xl) {
//         --main-header-height: 110px;
//     }

//     @include r($md) {
//         --main-header-height: 64px;
//     }
// }

// .main-header-outer {
//     min-height: var(--main-header-height);
// }

:target {
    // not works when anchor element has overflow hidden
    scroll-margin-top: var(--main-header-height);
}

.main-header {
    position: fixed;
    backface-visibility: hidden;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 0;
    transition-property: box-shadow;
    border-bottom: 1px solid $c-border;
    --logo-width: 225px;
    @include r($xxl) {
        --logo-width: 200px;
    }

    @include r($lg) {
        --logo-width: 150px;
    }

    @include r($md) {
        --logo-width: 120px;
    }

    @include r($sm) {
        --logo-width: 100px;
    }
    &__content {
        @include r($lg) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    &__logo,
    &__logo-img {
        width: var(--logo-width);
    }

    &__top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14rem 0;
        border-bottom: 3px solid $c-accent;
        @include r($lg) {
            width: calc(100% - 30px);
            border-bottom: none;
            padding: 10rem 0;
        }
    }

    .search-trigger {
        display: none;
        @include r($lg) {
            display: block;
            font-size: 25px;
            color: $c-accent;
            padding: 0.25em;
        }
        @include r($sm) {
            font-size: 20px;
            padding: 0.1em;
        }
    }

    &__search {
        width: calc((100% - var(--logo-width)) / 2);
        .search-submit {
            .sprite-icon {
                font-size: 20px;
                color: $c-accent;
            }
            @include r($lg) {
                padding-left: 0.25em;
                padding-right: 0.25em;
            }
        }

        .search-form {
            display: flex;
            align-items: center;

            &__inner {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                max-width: 755px;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                align-items: center;
            }

            @include r($lg) {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                border-top: 1px solid #eaeaea;
                border-bottom: 1px solid #eaeaea;
                background: #f7f9fa;
                opacity: 0;
                visibility: hidden;
                z-index: -9;
                @include tr;
            }
        }

        .search-input {
            border: none;
            outline: none;
            padding: 5rem 15rem;
            min-width: 220px;
            background-color: transparent;
            &::placeholder {
                color: $c-accent;
                opacity: 1;
            }

            &::-ms-input-placeholder {
                color: $c-accent;
            }
            @include r($lg) {
                padding: 0.9em 0.8em;
                width: 100%;
                flex-shrink: 1;
            }
        }
        @include r($lg) {
            width: auto;
            order: 3;
            margin-left: 10rem;
        }
    }

    &__user {
        width: calc((100% - var(--logo-width)) / 2);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include r($lg) {
            width: auto;
            margin-left: auto;
        }
    }

    .user-button {
        display: flex;
        align-items: center;
        color: $c-accent;
        &:hover {
            color: $c-color2;
        }
        &__icon {
            font-size: 20px;
            margin-right: 5px;
        }
        &__text {
            @include r($md) {
                display: none;
            }
        }
    }

    .subscribe-button {
        background-color: $c-color2;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 0.8em 1.25em;
        border-radius: 10px;
        margin-left: 1.5em;
        @include tr;
        &:hover {
            background-color: $c-accent;
            @include r($md) {
                background-color: transparent;
            }
        }
        .sprite-icon {
            font-size: 20px;
            margin-right: 6px;
            @include r($sm) {
                font-size: 18px;
            }
        }
        @include r($lg) {
            padding: 0.5em 0.8em;
            border-radius: 6px;
            margin-left: 1em;
            margin-left: 0.75em;
        }
        @include r($sm) {
            background-color: transparent;
            color: $c-accent;
            padding: 0.05em;
        }
        &__text {
            @include r($sm) {
                display: none;
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__menu-opener {
        @include rmin($lg) {
            display: none;
        }
    }

    &__weather {
        display: flex;
        align-items: center;
        padding-top: 16rem;
        padding-bottom: 16rem;
        padding-left: 25rem;
        font-size: 18rem;
        flex-shrink: 0;
        .sprite-icon {
            width: 34rem;
            height: 34rem;
            color: #edbe5e;
            margin-right: 6px;
        }
        @include r($xxl) {
            padding-left: 15rem;
        }
        @include r($lg) {
            display: none;
        }
    }

    &.is-scrolled {
        transition: 0.3s;
        box-shadow: $box-shadow;

        .main-header {
            &__logo-img {
                // width: 100rem;
                transition: 0.3s;

                // Edge (legacy) flickering fix
                @supports (-ms-ime-align: auto) {
                    transition: none;
                }
            }
        }
    }

    &.active-search {
        .search-form {
            @include r($lg) {
                opacity: 1;
                visibility: visible;
                z-index: 2;
            }
        }
    }
}
