.embed {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    background-color: #000;

    &::before {
        display: block;
        content: "";
    }

    &__item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    &__poster {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        object-fit: cover;
        overflow: hidden;
        transition: 0.3s;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &_filter_darkened {
            &::after {
                background-color: rgba(#000000, 0.18);
            }
        }

        &_filter_dark-gradient {
            &::after {
                background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0.01) 99%, rgba(0, 0, 0, 0) 100%);
            }
        }
    }

    &__play {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        color: #fff;

        &:hover {
            .embed__play-inner {
                transform: scale(1.2);
            }
        }
    }

    &__play-inner {
        display: flex;
        margin: auto;
        font-size: 160rem;
        transition: 0.3s;

        @include r($md) {
            font-size: 84px;
        }
    }

    &__play-img {
        display: block;
        margin: auto;
        transition: 0.3s;
        position: relative;

        &_color_black {
            color: $c-darkgray;
        }
    }

    &_16x9 {
        &::before {
            padding-bottom: 56.25%;
        }
    }

    &_4x3 {
        &::before {
            padding-bottom: 75%;
        }
    }

    &_custom-ratio {
        &::before {
            padding-bottom: 56.25%;
            padding-bottom: calc(var(--ratio-y) / var(--ratio-x) * 100%);
        }
    }

    &_state_play {
        .embed {
            &__poster {
                opacity: 0;
                visibility: hidden;
            }

            &__play {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
