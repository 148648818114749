// Text
$default-font: "Open Sans", sans-serif;
$secondary-font: "Archivo", sans-serif;
$default-font-size: 18rem;
$line-height: 1.5;

// Colors
$c-text: var(--c-text);
$c-orange: var(--c-orange);
$c-accent: var(--c-accent);
$c-primary: var(--c-accent);
$c-accent-active: var(--c-accent-active);
$c-color2: var(--c-color2);
$c-color3: var(--c-color3);
$c-color4: var(--c-color4);
$c-lightgray: var(--c-light-gray);
$c-accent-hover: var(--c-accent-active);
$c-gray: var(--c-gray);
$c-darkgray: var(--c-dark-gray);
$c-disabled: var(--c-gray);
$c-disabled-text: var(--c-dark-gray);
$c-border: #c8d0c8;
$c-red: #e83041;
$c-blue: var(--c-blue);

$box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);

// Default transition duration
$trs-dur: 0.3s;

// Sizes
$rem: 1px;
$rem-mobile: 0.75px;

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 30px;
$grid-columns: 12;

// Media sizes
$xs: 359.98px;
$sm: 575.98px;
$md: 767.98px;
$lg: 991.98px;
$xl: 1199.98px;
$xxl: 1439.98px;

$sm-c: 570px;
$md-c: 755px;
$lg-c: 970px;
$xl-c: 1170px;
$xxl-c: 1430px;

$grid-breakpoints: (
    xs: 0,
    sm: $sm,
    md: $md,
    lg: $lg,
    xl: $xl,
    xxl: $xxl
);
$container-max-widths: (
    sm: $sm-c,
    md: $md-c,
    lg: $lg-c,
    xl: $xl-c,
    xxl: $xxl-c
);
$gutter: $grid-gutter-width;
