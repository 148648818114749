body {
    padding-top: var(--main-header-height);
    // padding-top: calc(2px + var(--main-header-height));
}

.badge {
    font-size: 16px;
    font-weight: 600;
    font-family: $secondary-font;
    background-color: $c-color4;
    padding: 8rem 20rem;
    color: #fff;
    display: inline-block;
    border-radius: 20px;
    @include r($lg) {
        font-size: 14px;
    }
}

.main {
    min-height: 200px;
}

#hero-slider,
.news-article-top {
    position: relative;
    .hero-slide {
        display: flex;
        height: 680px;
        @include r($lg) {
            flex-wrap: wrap;
            height: unset;
        }
        &__left {
            width: 43%;
            background-color: $c-color3;
            padding-left: calc((100vw - 14px - 1400px) / 2);
            padding-right: 5%;
            display: flex;
            align-items: center;
            @include r($xxl) {
                padding-left: calc((100vw - 14px - 1140px) / 2);
            }
            @include r($xl) {
                padding-left: calc((100vw - 10px - 940px) / 2);
            }
            @include rmin($lg) {
                .container {
                    max-width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            @include r($lg) {
                order: 2;
                width: 100%;
                height: 320px;
                padding: 2% 0;
            }
            @include r($sm) {
                height: 380px;
            }
        }

        &_inner {
        }

        &__right {
            width: 57%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @include r($lg) {
                order: 1;
                width: 100%;
                img {
                    max-height: 60vw;
                    height: 400px;
                }
            }
        }

        &__title {
            font-size: 42px;
            line-height: 1;
            font-weight: 600;
            font-family: $secondary-font;
            margin-top: 0.4em;
            @include r($xxl) {
                font-size: 32px;
            }
            @include r($xl) {
                font-size: 28px;
            }
            @include r($lg) {
                font-size: 24px;
            }
        }

        &__time {
            font-size: 14px;
            display: inline-flex;
            align-items: center;
            gap: 12px;
            margin-top: 1em;
            span {
                display: inline-flex;
                align-items: center;
                svg.sprite-icon {
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }

        &__text {
            margin-top: 1em;
            line-height: 1.5;
            font-size: 18px;
            @include r($xxl) {
                font-size: 16px;
            }
            @include r($sm) {
                font-size: 14px;
            }
        }

        &__buttons {
            display: flex;
            column-gap: 3%;
            margin-top: 1.5em;
            .button {
                @include rmin($xl) {
                    padding-left: 1.5em;
                    padding-right: 1.5em;
                }
                @include rmin($xxl) {
                    padding-left: 2em;
                    padding-right: 2em;
                }
                @include r($xxl) {
                    font-size: 14px;
                }
                @include r($md) {
                    font-size: 16rem;
                }
            }
        }
    }

    .hero-slider-controls {
        background-color: #fff;
        border-radius: 6px 6px 0 0;
        position: absolute;
        bottom: 0;
        right: calc((100vw - 14px - 1400px) / 2);
        z-index: 11;
        font-size: 14px;
        display: flex;
        align-items: center;
        .hero-slide-pagination {
            margin-left: 1.25em;
            margin-right: 1.25em;
        }
        .hero-button-prev {
            margin-left: 20px;
            margin-top: 9px;
            margin-bottom: 9px;
        }
        .hero-button-next {
            margin-right: 20px;
            margin-top: 9px;
            margin-bottom: 9px;
        }
        @include r($xxl) {
            right: calc((100vw - 14px - 1140px) / 2);
        }
        @include r($xl) {
            right: calc((100vw - 10px - 940px) / 2);
        }
        @include r($lg) {
            right: 20px;
            bottom: 320px;
            background-color: $c-color3;
        }
        @include r($sm) {
            bottom: 380px;
        }
    }
}

.advert {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    &__text {
        font-size: 13px;
        font-style: italic;
        text-align: right;
        margin-top: 0.5em;
        @include r($sm) {
            font-size: 11px;
        }
    }
    &__img {
        img {
            @include tr;
        }
        &:hover {
            img {
                transform: scale(0.95);
                // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.section-title {
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $c-border;
        margin-top: 1em;
        margin-bottom: 2.5em;
        padding-bottom: 0.5em;
    }

    &__button {
        display: flex;
        align-items: center;
        .sprite-icon {
            font-size: 1.3em;
            margin-left: 0.5em;
        }
    }
}

.latest-news {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2.5%;
    height: 100%;

    .article {
        margin-bottom: 40px;
    }

    .article:nth-child(1) {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 3;
        .article__title {
            font-size: 42rem;
            @include r($xxl) {
                font-size: 32rem;
            }
            @include r($xl) {
                font-size: 26rem;
            }
        }
    }
    .article:nth-child(2) {
        grid-row-start: 1;
        grid-column-start: 3;
        grid-row-end: 2;
        grid-column-end: 4;
    }
    .article:nth-child(3) {
        grid-row-start: 1;
        grid-column-start: 4;
        grid-row-end: 2;
        grid-column-end: 5;
    }
    .article:nth-child(4) {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-row-end: 3;
        grid-column-end: 4;
    }
    .article:nth-child(5) {
        grid-row-start: 2;
        grid-column-start: 4;
        grid-row-end: 3;
        grid-column-end: 5;
    }
    @include r($lg) {
        display: flex;
        flex-wrap: wrap;
        .advert {
            width: 48%;
        }
        .article {
            width: 48%;
            &:nth-child(1) {
                width: 100%;
            }
        }
    }
    @include r($sm) {
        .advert {
            width: 100%;
            margin-bottom: 2em;
        }
        .article {
            width: 100%;
        }
    }
}

.article {
    margin-bottom: 40rem;
    display: block;
    &__image {
        @include tr;
        width: 100%;
        padding-top: 66%;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        picture {
            display: block;
            width: 100%;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
        }
    }
    &__tag {
        margin-top: 1em;
        margin-bottom: 1em;
        width: 100%;
    }
    &__title {
        width: 100%;
        font-size: 24rem;
        font-weight: 600;
        line-height: 1.2;
        @include tr;
        @include r($xxl) {
            font-size: 20rem;
        }
        @include r($xl) {
            font-size: 18rem;
        }
        @include r($md) {
            font-size: 16px;
        }
    }
    &__time {
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        gap: 12px;
        margin-top: 1em;
        span {
            display: inline-flex;
            align-items: center;
            svg.sprite-icon {
                display: inline-block;
                margin-right: 5px;
            }
        }
        @include r($sm) {
            font-size: 12px;
        }
    }
    &__text {
        margin-top: 1em;
        @include line-clamp(18px, 1.5, 3);
        @include r($xxl) {
            @include line-clamp(16px, 1.5, 3);
            margin-top: 0.5em;
        }
        @include r($md) {
            @include line-clamp(13px, 1.5, 3);
        }
        @include r($sm) {
            @include line-clamp(14px, 1.5, 3);
        }
    }
    &:hover {
        .article__image {
            opacity: 0.8;
        }
        .article__title {
            color: $c-color2;
        }
    }
}

.tag {
    font-weight: 600;
    color: $c-color4;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    font-family: $secondary-font;
    @include r($xxl) {
        font-size: 14px;
    }
    @include r($xl) {
        font-size: 12px;
    }
    &:before {
        content: "";
        display: inline-block;
        background-color: currentColor;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        margin-right: 0.5em;
    }
}

.subscribe-section {
    padding-top: 40rem;
    padding-bottom: 30rem;
    height: 620px;
    position: relative;
    @include r($xxl) {
        height: 540px;
    }
    @include r($xl) {
        height: 480px;
    }
    @include r($lg) {
        height: auto;
    }
    .container {
        display: flex;
        justify-content: space-between;
        @include r($lg) {
            flex-wrap: wrap;
        }
    }
    &__left {
        width: 50%;
        padding-top: 40rem;
        @include r($lg) {
            width: 100%;
            padding-top: 10rem;
        }
    }
    &__right {
        width: 50%;
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-end;
        column-gap: 40px;
        @include r($lg) {
            width: 100%;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-top: 3em;
        }
        @include r($sm) {
            display: block;
        }
        .current_issue_img {
            display: block;
            width: 390px;
            border: 3px solid $c-accent;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            transition: all 0.5s linear;
            &:hover {
                transform: scale(1.03);
                box-shadow: 0px 8px 12px 10px rgba(0, 0, 0, 0.45);
            }
            img {
                width: 100%;
            }
            @include r($xxl) {
                width: 250px;
            }
            @include r($lg) {
                margin-bottom: 1em;
            }
        }
    }
    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url("../img/_dist/pattern.png");
        background-repeat: repeat;
        z-index: -1;
    }
    background-color: #dde5e5;
    // background-color: #06060649;
    background-image: url("../img/_dist/layer-4.svg"), url("../img/_dist/layer-3.svg"), url("../img/_dist/layer-1.svg");
    background-repeat: no-repeat;
    background-position:
        0 100%,
        0 100%,
        0 100%;
    background-size:
        auto,
        100% auto,
        100% auto;
    transition: background 0.8s linear;
    @include r($sm) {
        background-position:
            0 108%,
            0 100%,
            0 98%;
    }
    &:hover {
        background-position:
            -5% 100%,
            0 100%,
            0 105%;
        background-size:
            auto,
            100% auto,
            100% auto;
        @include r($sm) {
            background-position:
                15% 106%,
                0 100%,
                0 96%;
        }
    }

    .tractor-green {
        margin-top: 22%;
        margin-left: 40%;
        position: relative;
        width: 224px;
        @include r($xxl) {
            width: 175px;
        }
        @include r($xl) {
            width: 140px;
        }
        @include r($lg) {
            display: none;
        }
        img {
            max-width: 100%;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 40px;
            height: 40px;
            bottom: 92%;
            right: 28%;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 4335 4335' style='enable-background:new 0 0 512 512' xml:space='preserve' fill-rule='evenodd'><g><g fill-rule='nonzero'><path fill='%232b3718' d='M3018 2023c-43 121-36 238 2 347 40 113 112 220 196 314 143 162 254 347 305 545 46 175 44 361-25 552-30 80-75 165-129 244-52 74-114 143-178 195l-130 104c-21 17-53 14-71-8-5-7-8-14-10-21-81-375-74-382-8-452 27-29 68-73 118-210 44-121 37-238-2-348-39-113-111-219-195-314-143-162-254-346-306-544-45-176-43-362 26-552 48-131 107-227 181-311 72-83 156-153 255-232 22-18 54-14 71 8 6 6 9 13 10 21h1c80 374 74 381 7 452-26 28-67 72-118 210zM1247 2023c-44 121-37 238 2 347 39 113 111 220 195 314 143 162 254 347 306 545 45 175 43 361-26 552-29 80-74 165-129 243v1c-52 74-113 143-178 195l-129 104c-22 17-54 14-71-8-6-7-9-14-11-21-80-375-74-382-7-452 26-29 67-73 118-210 43-121 36-238-2-348-40-113-112-219-196-314-143-162-254-346-305-544-46-176-44-362 25-552 48-131 108-227 181-311 72-83 157-153 255-232 22-18 54-14 72 8 5 6 8 13 10 21 81 374 74 381 8 452-27 28-68 72-118 210z' opacity='1' data-original='%232b3718'></path><path fill='%232b3718' d='M1943 702c-44 121-37 238 1 348 40 113 112 219 196 314 143 162 254 346 305 544 46 176 44 362-25 552-29 80-75 165-129 244-52 75-113 143-178 195l-129 104c-22 17-54 14-72-8-5-6-8-13-10-21-81-374-74-381-8-452 27-28 68-72 118-210 44-121 37-238-2-347-39-113-111-220-195-314-143-163-254-347-305-545-46-176-44-361 25-552 48-131 107-227 181-311 72-83 156-152 255-232 22-17 54-14 72 8 5 6 8 14 10 21 80 375 74 382 8 452-27 29-68 72-118 210z' opacity='1' data-original='%232b3718'></path></g></g></svg>");
            background-size: contain;
            animation: smoke 3s linear infinite;
            background-position: center bottom;
            opacity: 0;
            @include tr;
            @include r($xxl) {
                width: 32px;
                height: 32px;
            }
            @include r($xl) {
                width: 26px;
                height: 26px;
            }
        }
    }

    &.hovered {
        .tractor-green {
            &::after {
                opacity: 1;
            }
        }
    }
}

@keyframes smoke {
    0% {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 4335 4335' style='enable-background:new 0 0 512 512' xml:space='preserve' fill-rule='evenodd'><g><g fill-rule='nonzero'><path fill='%232b3718' d='M3018 2023c-43 121-36 238 2 347 40 113 112 220 196 314 143 162 254 347 305 545 46 175 44 361-25 552-30 80-75 165-129 244-52 74-114 143-178 195l-130 104c-21 17-53 14-71-8-5-7-8-14-10-21-81-375-74-382-8-452 27-29 68-73 118-210 44-121 37-238-2-348-39-113-111-219-195-314-143-162-254-346-306-544-45-176-43-362 26-552 48-131 107-227 181-311 72-83 156-153 255-232 22-18 54-14 71 8 6 6 9 13 10 21h1c80 374 74 381 7 452-26 28-67 72-118 210zM1247 2023c-44 121-37 238 2 347 39 113 111 220 195 314 143 162 254 347 306 545 45 175 43 361-26 552-29 80-74 165-129 243v1c-52 74-113 143-178 195l-129 104c-22 17-54 14-71-8-6-7-9-14-11-21-80-375-74-382-7-452 26-29 67-73 118-210 43-121 36-238-2-348-40-113-112-219-196-314-143-162-254-346-305-544-46-176-44-362 25-552 48-131 108-227 181-311 72-83 157-153 255-232 22-18 54-14 72 8 5 6 8 13 10 21 81 374 74 381 8 452-27 28-68 72-118 210z' opacity='1' data-original='%232b3718'></path><path fill='%232b3718' d='M1943 702c-44 121-37 238 1 348 40 113 112 219 196 314 143 162 254 346 305 544 46 176 44 362-25 552-29 80-75 165-129 244-52 75-113 143-178 195l-129 104c-22 17-54 14-72-8-5-6-8-13-10-21-81-374-74-381-8-452 27-28 68-72 118-210 44-121 37-238-2-347-39-113-111-220-195-314-143-163-254-347-305-545-46-176-44-361 25-552 48-131 107-227 181-311 72-83 156-152 255-232 22-17 54-14 72 8 5 6 8 14 10 21 80 375 74 382 8 452-27 29-68 72-118 210z' opacity='1' data-original='%232b3718'></path></g></g></svg>");
    }
    50% {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 4335 4335' style='enable-background:new 0 0 512 512' xml:space='preserve' fill-rule='evenodd'><g><g fill-rule='nonzero'><path fill='%23767676' d='M3018 2023c-43 121-36 238 2 347 40 113 112 220 196 314 143 162 254 347 305 545 46 175 44 361-25 552-30 80-75 165-129 244-52 74-114 143-178 195l-130 104c-21 17-53 14-71-8-5-7-8-14-10-21-81-375-74-382-8-452 27-29 68-73 118-210 44-121 37-238-2-348-39-113-111-219-195-314-143-162-254-346-306-544-45-176-43-362 26-552 48-131 107-227 181-311 72-83 156-153 255-232 22-18 54-14 71 8 6 6 9 13 10 21h1c80 374 74 381 7 452-26 28-67 72-118 210zM1247 2023c-44 121-37 238 2 347 39 113 111 220 195 314 143 162 254 347 306 545 45 175 43 361-26 552-29 80-74 165-129 243v1c-52 74-113 143-178 195l-129 104c-22 17-54 14-71-8-6-7-9-14-11-21-80-375-74-382-7-452 26-29 67-73 118-210 43-121 36-238-2-348-40-113-112-219-196-314-143-162-254-346-305-544-46-176-44-362 25-552 48-131 108-227 181-311 72-83 157-153 255-232 22-18 54-14 72 8 5 6 8 13 10 21 81 374 74 381 8 452-27 28-68 72-118 210z' opacity='1' data-original='%23767676'></path><path fill='%23767676' d='M1943 702c-44 121-37 238 1 348 40 113 112 219 196 314 143 162 254 346 305 544 46 176 44 362-25 552-29 80-75 165-129 244-52 75-113 143-178 195l-129 104c-22 17-54 14-72-8-5-6-8-13-10-21-81-374-74-381-8-452 27-28 68-72 118-210 44-121 37-238-2-347-39-113-111-220-195-314-143-163-254-347-305-545-46-176-44-361 25-552 48-131 107-227 181-311 72-83 156-152 255-232 22-17 54-14 72 8 5 6 8 14 10 21 80 375 74 382 8 452-27 29-68 72-118 210z' opacity='1' data-original='%23767676'></path></g></g></svg>");
    }
    100% {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 4335 4335' style='enable-background:new 0 0 512 512' xml:space='preserve' fill-rule='evenodd'><g><g fill-rule='nonzero'><path fill='%232b3718' d='M3018 2023c-43 121-36 238 2 347 40 113 112 220 196 314 143 162 254 347 305 545 46 175 44 361-25 552-30 80-75 165-129 244-52 74-114 143-178 195l-130 104c-21 17-53 14-71-8-5-7-8-14-10-21-81-375-74-382-8-452 27-29 68-73 118-210 44-121 37-238-2-348-39-113-111-219-195-314-143-162-254-346-306-544-45-176-43-362 26-552 48-131 107-227 181-311 72-83 156-153 255-232 22-18 54-14 71 8 6 6 9 13 10 21h1c80 374 74 381 7 452-26 28-67 72-118 210zM1247 2023c-44 121-37 238 2 347 39 113 111 220 195 314 143 162 254 347 306 545 45 175 43 361-26 552-29 80-74 165-129 243v1c-52 74-113 143-178 195l-129 104c-22 17-54 14-71-8-6-7-9-14-11-21-80-375-74-382-7-452 26-29 67-73 118-210 43-121 36-238-2-348-40-113-112-219-196-314-143-162-254-346-305-544-46-176-44-362 25-552 48-131 108-227 181-311 72-83 157-153 255-232 22-18 54-14 72 8 5 6 8 13 10 21 81 374 74 381 8 452-27 28-68 72-118 210z' opacity='1' data-original='%232b3718'></path><path fill='%232b3718' d='M1943 702c-44 121-37 238 1 348 40 113 112 219 196 314 143 162 254 346 305 544 46 176 44 362-25 552-29 80-75 165-129 244-52 75-113 143-178 195l-129 104c-22 17-54 14-72-8-5-6-8-13-10-21-81-374-74-381-8-452 27-28 68-72 118-210 44-121 37-238-2-347-39-113-111-220-195-314-143-163-254-347-305-545-46-176-44-361 25-552 48-131 107-227 181-311 72-83 156-152 255-232 22-17 54-14 72 8 5 6 8 14 10 21 80 375 74 382 8 452-27 29-68 72-118 210z' opacity='1' data-original='%232b3718'></path></g></g></svg>");
    }
}

.link_with_icon {
    display: inline-flex;
    align-items: center;
    span + .sprite-icon {
        margin-left: 8px;
    }
    .sprite-icon + span {
        margin-left: 8px;
    }
    @include r($sm) {
        // font-size: 16px;
        &.button-on-mobile {
            background-color: $c-accent;
            color: #fff;
            transition: $trs-dur;
            padding: 0.95em 1.5em 0.95em;
            font-family: $secondary-font;
            border-radius: 4px;
            border: 1px solid $c-accent;
            font-weight: 600;
            line-height: 1.2;
            text-decoration: none;
            &:hover {
                background-color: $c-color4;
                border-color: $c-color4;
                outline: 0;
                color: #fff;
            }
        }
    }
}

.buttons-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;

    .button {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
        @include rmin($xl) {
            padding-left: 1.5em;
            padding-right: 1.5em;
        }
        @include rmin($xxl) {
            padding-left: 2em;
            padding-right: 2em;
        }
        @include r($xxl) {
            font-size: 14px;
        }
        @include r($md) {
            font-size: 16rem;
        }
    }
}

.featured-button-prev,
.featured-button-next {
    background: $c-accent;
    color: #fff;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10rem;
    cursor: pointer;
    @include tr;
    &:hover {
        background-color: $c-color2;
    }
}

.featured-slider {
    &__area {
        margin-top: 50rem;
    }
}

#featured-slider {
    padding-top: 60rem;
    .article {
        .featured_no {
            position: absolute;
            top: -70rem;
            font-size: 42rem;
            color: $c-color4;
            font-family: $secondary-font;
            font-weight: 600;
            @include r($xxl) {
                font-size: 36rem;
            }
            @include r($xl) {
                font-size: 32rem;
            }
        }
    }
}

.advertise-submit {
    display: flex;
    margin-top: 20rem;
    @include r($sm) {
        flex-wrap: wrap;
    }
    &__a,
    &__s {
        width: 50%;
        position: relative;
        color: #fff;
        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #00000095;
        }
        @include r($sm) {
            width: 100%;
            margin-bottom: 1em;
        }
    }
    &__a {
        background-image: url(../img/_dist/Syngentas-GrowMore.jpg);
        background-size: cover;
        border-radius: 10rem 0 0 10rem;
        overflow: hidden;
        // background-position: center;
        &:after {
            background-color: #00000080;
        }
    }
    &__s {
        background-image: url(../img/_dist/rural-business-2.jpg);
        background-size: cover;
        border-radius: 0 10rem 10rem 0;
        overflow: hidden;
        background-position: center;
    }
    &-content {
        position: relative;
        z-index: 1;
        padding: 10% 8% 8%;
        .h2 {
            display: flex;
            align-items: center;
            margin-bottom: 0.25em;
            @include r($xl) {
                font-size: 32rem;
            }
            @include r($lg) {
                font-size: 28rem;
            }
            .sprite-icon {
                color: #fff;
                margin-right: 0.2em;
                position: relative;
                left: -0.15em;
            }
        }
        .button {
            color: #fff;
            border-color: #fff;
            margin-top: 1.5em;
            &:hover {
                background-color: #fff;
                border-color: #fff;
                color: #000;
            }
        }
    }
}

.testimonial {
    &__area {
        background-color: $c-gray;
        margin-top: -120rem;
        padding-top: 200rem;
        padding-bottom: 50rem;
        &__title {
            text-align: center;
            border-bottom: 1px solid #b8c7b8;
            padding-bottom: 2em;
        }
        @include r($xl) {
            margin-top: -80rem;
            padding-top: 150rem;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 4%;
        margin-top: 3em;
        padding-left: 2%;
        padding-right: 2%;
        @include r($lg) {
            display: block;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__quote {
        width: 1.45em;
        height: 1.45em;
        background-color: $c-color4;
        color: #fff;
        border-radius: 50%;
        font-size: 44rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include r($xxl) {
            font-size: 36rem;
        }
    }

    &__text {
        font-size: 24rem;
        line-height: 1.4;
        font-weight: 600;
        margin-top: 1em;
        @include r($xxl) {
            font-size: 20rem;
        }
        @include r($xl) {
            font-size: 18rem;
        }
        @include r($lg) {
            font-size: 20rem;
        }
    }

    &__author {
        margin-top: 2em;
        font-weight: 600;
        @include r($lg) {
            margin-top: 1em;
        }
    }

    &__org {
        margin-top: 0.25em;
    }
    @include r($lg) {
        margin-bottom: 3em;
    }
}

.image-and-text {
    display: flex;
    margin-top: 100rem;
    margin-bottom: 150rem;
    @include r($lg) {
        flex-wrap: wrap;
        margin-top: 60rem;
        margin-bottom: 80rem;
    }
    @include r($sm) {
        margin-bottom: 50rem;
    }
    &__t {
        width: 40%;
        padding-right: 3%;
        @include r($lg) {
            width: 100%;
            padding-right: 0;
        }
    }
    &__i {
        width: 60%;
        padding-left: 3%;
        @include r($lg) {
            width: 100%;
            padding-left: 0;
            margin-top: 25rem;
        }
        .double-image {
            position: relative;
            padding-bottom: 8%;
            height: 100%;
            @include r($lg) {
                display: flex;
                padding-bottom: 0;
                gap: 2%;
            }
            &__big {
                margin-left: auto;
                display: block;
                max-width: 85%;
                height: 100%;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 8px;
                    @include object-fit-cover;
                }
                @include r($lg) {
                    max-width: unset;
                    margin-left: unset;
                    width: 50%;
                    border: 6px solid #fff;
                    border-radius: 10px;
                    border: none;
                }
            }
            &__small {
                border: 6px solid #fff;
                position: absolute;
                max-width: 40%;
                max-height: 50%;
                bottom: 0;
                z-index: 2;
                border-radius: 8px;
                aspect-ratio: 1/1;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 8px;
                    @include object-fit-cover;
                }
                @include r($lg) {
                    position: static;
                    max-width: unset;
                    max-height: unset;
                    width: 50%;
                    border-radius: 10px;
                    border: none;
                }
            }
        }
    }
}

.info-box {
    background-color: $c-color3;
    padding: 1em 1.2em;
    border-radius: 0.4em;
}

.big-icon {
    font-size: 60rem;
    text-align: center;
    font-weight: 900;
    @include r($md) {
        font-size: 40rem;
    }
    .sprite-icon {
        display: inline-block;
    }
}

.issue-single-content {
    text-align: left;
    .isc-block {
        margin-bottom: 30px;
        h3,
        h4 {
            margin-bottom: 15px;
            text-transform: uppercase;
            font-weight: 700;
        }
        ul {
            margin-bottom: 0;
            line-height: 1.9;
            font-size: 16px;
            @include r($md) {
                padding-left: 1em;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
