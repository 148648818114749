// reset default styles
button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: inherit;
    text-align: center;
    text-decoration: none;
    max-width: 100%;
    line-height: 1;
    padding: 0;
    transition: $trs-dur;
}

.button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    max-width: 100%;
    text-align: center;
    transition: $trs-dur;
    padding: 0.95em 2.5em 0.95em;
    font-family: $secondary-font;
    font-size: 16rem;
    font-weight: 600;
    line-height: 1.2;
    text-decoration: none;
    color: #ffffff;
    background: $c-text;
    border: 1px solid $c-text;
    border-radius: 4px;
    &:hover {
        background-color: $c-color2;
        border-color: $c-color2;
        outline: 0;
        color: #fff;
        .button__arrow {
            transform: translateX(0.25em);
        }
    }
    &:active {
        background-color: $c-accent-active;
        border-color: $c-accent-active;
        color: #fff;
    }
    &[disabled] {
        background-color: $c-disabled;
        border-color: $c-disabled-text;
        color: $c-disabled-text;
        pointer-events: none;
        cursor: default;
        .button {
            &__loader {
                display: block;
            }
            &__arrow-outer {
                display: none;
            }
        }
    }
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    &__icon {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        font-size: 1.1em;
        width: 1em;
        & + .button__text {
            margin-left: 0.4em;
        }
    }
    &__icon-img {
        @include centerer();
    }
    &__text {
        vertical-align: middle;
        & + .button__arrow-outer {
            margin-left: 2em;
        }
        & + .button__icon {
            margin-left: 1em;
        }
    }
    &__arrow-outer {
        position: relative;
        display: inline-block;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -1em;
            transform: translateY(-50%);
            width: 1px;
            height: 1.5em;
            opacity: 0.2;
            @include r($md) {
                height: 2.4167em;
            }
        }
    }
    &__arrow {
        display: block;
        transition: transform 0.3s;
        font-size: 1.5em;
        min-width: 1em;
        position: relative;
        @include r($md) {
            font-size: 2em;
        }
    }
    &__arrow-img {
        @include centerer();
    }
    &__loader {
        display: none;
        min-width: 1em;
        position: relative;
        margin-left: 1.35em;
    }
    &__loader-inner {
        @include centerer();
    }
    &_bg_red {
        background-color: $c-red;
        border-color: $c-red;
    }
    &_type_ghost {
        background: transparent;
        border-color: $c-accent;
        color: $c-accent;
        &:hover {
            background-color: $c-color2;
            border-color: $c-color2;
        }
    }
    &_size_small {
        padding: 10px;
    }
    &_red {
        background-color: $c-color2;
        border-color: $c-color2;
        &:hover {
            background-color: $c-accent;
            border-color: $c-accent;
        }
    }
    &_size_large {
        padding: 24px 24px 23px 32px;
        .button__arrow-outer::before {
            @include rmin($md) {
                height: 3em;
            }
        }
    }
}
